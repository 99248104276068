<template>
  <default-modal v-model="dialogModel">
    <v-card-actions>
      <v-form ref="form">
        <v-radio-group
          v-model="blockId"
          :rules="[rules.required]"
          class="mt-0"
          active-class="font-weight-bold"
          @change="discrepantPanelModels = initialValueForDiscrepantPanelModels"
        >
          <v-radio
            label="Divergência de quantidade de painéis"
            :value="1"
          ></v-radio>
          <v-radio label="Divergência de modelo de painel" :value="2"></v-radio>
          <v-select
            ref="select-1"
            :rules="[rules.discrepantPanelModelsRequired]"
            v-model="discrepantPanelModels"
            class="ml-8 mt-1 mb-n2"
            :items="panelModels"
            item-text="painelDescricao"
            item-value="painelId"
            clearable
            multiple
            outlined
            chips
            deletable-chips
            label="Modelos Divergentes"
            :dense="discrepantPanelModels.length === 0"
            v-if="blockId === 2"
            style="font-size: 13px !important"
          >
            <template v-slot:prepend-item>
              <div class="d-flex justify-content-end">
                <v-icon
                  @click="$refs['select-1'].isMenuActive = false"
                  color="primary"
                  class="mr-2 mt-0 mb-1"
                  >mdi-close-circle-outline</v-icon
                >
              </div>
              <v-divider />
            </template>
          </v-select>
          <v-radio
            label="Divergência de quantidade e modelo de painel"
            :value="3"
          ></v-radio>
          <v-select
            ref="select-2"
            :rules="[rules.discrepantPanelModelsRequired]"
            v-model="discrepantPanelModels"
            class="ml-8 mt-2 mb-n2"
            :items="panelModels"
            item-text="painelDescricao"
            item-value="painelId"
            clearable
            multiple
            outlined
            chips
            deletable-chips
            label="Modelos Divergentes"
            :dense="discrepantPanelModels.length === 0"
            v-if="blockId === 3"
            style="font-size: 13px !important"
          >
            <template v-slot:prepend-item>
              <div class="d-flex justify-content-end">
                <v-icon
                  @click="$refs['select-2'].isMenuActive = false"
                  color="primary"
                  class="mr-2 mt-0 mb-1"
                  >mdi-close-circle-outline</v-icon
                >
              </div>
              <v-divider />
            </template>
          </v-select>
          <v-radio label="Avaria de painel" :value="4"></v-radio>
          <!-- <v-select
            ref="select-2"
            :rules="[rules.discrepantPanelModelsRequired]"
            v-model="discrepantPanelModels"
            class="ml-8 mt-2 mb-n8"
            :items="panelModels"
            item-text="painelDescricao"
            item-value="painelId"
            clearable
            multiple
            outlined
            chips
            deletable-chips
            label="Modelos Divergentes"
            :dense="discrepantPanelModels.length === 0"
            v-if="blockId === 3"
            style="font-size: 13px !important"
          >
            <template v-slot:prepend-item>
              <div class="d-flex justify-content-end">
                <v-icon
                  @click="$refs['select-2'].isMenuActive = false"
                  color="primary"
                  class="mr-2 mt-0 mb-1"
                  >mdi-close-circle-outline</v-icon
                >
              </div>
              <v-divider />
            </template>
          </v-select> -->
        </v-radio-group>
      </v-form>
    </v-card-actions>
    <v-card-actions>
      <default-button
        @click="confirmDiscrepancy(orderToBeBlocked)"
        :loading="isLoading"
        >Bloquear Pedido</default-button
      >
    </v-card-actions>
  </default-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DefaultButton from "@/shared/components/vuetify/DefaultButton.vue";
import DefaultModal from "@/shared/components/vuetify/DefaultModal.vue";

export default {
  name: "BlockOrder",
  components: {
    DefaultButton,
    DefaultModal,
  },
  data() {
    return {
      blockId: "",
      discrepantPanelModels: [],
      isLoading: false,
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    panelModels: {
      type: Array,
      required: true,
    },
  },
  methods: {
    ...mapActions("panelInspection", ["blockOrder"]),
    async confirmDiscrepancy(orderToBeBlocked) {
      this.isLoading = true;
      if (this.$refs.form.validate()) {
        await this.blockOrder(orderToBeBlocked);
      }
      this.isLoading = false;
    },
  },
  computed: {
    ...mapGetters("panelInspection", ["getVolumeDetails"]),
    dialogModel: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    rules() {
      return {
        required: (input) => !!input || "Selecione um das divergências acima",
        discrepantPanelModelsRequired: (input) =>
          !!input.length > 0 || "Selecione os modelos divergentes",
      };
    },
    orderToBeBlocked() {
      const blockIdsRequiringDiscrepantPanelModels = [2, 3];
      const requiresDiscrepantPanelModels =
        blockIdsRequiringDiscrepantPanelModels.includes(this.blockId);
      return {
        pedidoId: this.getVolumeDetails.pedidoId,
        motivo: this.blockId,
        paineisDivergentes: requiresDiscrepantPanelModels
          ? this.discrepantPanelModels
          : [],
      };
    },
    initialValueForDiscrepantPanelModels() {
      const hasOnlyOnePanelModel = this.panelModels.length === 1;
      const singlePanelModelId = this.panelModels[0].painelId;
      return hasOnlyOnePanelModel ? [singlePanelModelId] : [];
    },
  },
};
</script>

<style scoped>
::v-deep .v-list-item__title {
  font-size: 13px;
}
</style>
